<template>
  <div class="content-container">
    <div class="page-card">
      <h3>Карточка артикула</h3>
      <a-tabs type="card" @change="tabChange">
        <a-tab-pane key="common" tab="Общая информация">
          <products-common :product="product" />
        </a-tab-pane>
        <a-tab-pane key="photo" tab="Фото">
          <products-photos :product="product" />
        </a-tab-pane>
        <a-tab-pane key="file" tab="Спецификации">
          <products-files :product="product" />
        </a-tab-pane>
      </a-tabs>
      <div class="product__footer" style="gap: 8px">
        <a-form-item>
          <a @click="$router.push('/')">В каталог</a>
        </a-form-item>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"

import { API_PREFIX } from "@/services/api/v1/const"
import axios from "axios"
import ProductsCommon from "@/components/products/ProductsCommon.vue"
import ProductsPhotos from "@/components/products/ProductsPhotos.vue"
import ProductsFiles from "@/components/products/ProductsFiles.vue"

export default {
  name: "ProductView",

  components: {
    ProductsCommon,
    ProductsPhotos,
    ProductsFiles,
  },

  data() {
    return {
      tab: "common",
      product: { data: { code: "" } },
    }
  },

  computed: {
    ...mapState("auth", {
      user: state => state.user,
    }),
  },

  methods: {
    tabChange() {},

    async remote(clojure) {
      try {
        await clojure()
      } catch (e) {
        console.log("error", e)
      }
    },

    async fetchProduct() {
      const id = parseInt(this.$route.params.id)
      const response = await axios.get(`${API_PREFIX}/product/${id}`)
      return response.data
    },
  },
  async mounted() {
    this.remote(async () => {
      this.product = await this.fetchProduct()
    })
  },
  created() {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchProduct()
        this.tab = "common"
      }
    )
  },
}
</script>

<style lang="scss">
.product {
  &__footer {
    display: flex;
    width: 100%;
    margin-top: 50px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
</style>
