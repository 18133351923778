<template>
  <a-modal
    :visible="dataCarouselVisible"
    width="unset"
    @ok="close"
    @cancel="close"
  >
    <template slot="footer">
      <a-button key="back" @click="close()">Закрыть</a-button>
    </template>
    <div>
      <div>
        <div class="d-flex">
          <div v-if="items.length > 1" class="d-flex align-center">
            <a-button
              color="primary"
              icon="mdi-arrow-left"
              class="mr-2 icon-button"
              @click="prev()"
            >
              <a-icon type="left" />
            </a-button>
          </div>
          <div class="wrapper border border-width-2 rounded d-flex">
            <img v-if="screenImage" :src="screenImage.link" class="image" />
            <div v-else>Нет изображения</div>
          </div>
          <div v-if="items.length > 1" class="d-flex align-center">
            <a-button
              color="primary"
              icon="mdi-arrow-right"
              class="ml-2 icon-button"
              @click="next()"
            >
              <a-icon type="right" />
            </a-button>
          </div>
        </div>
      </div>
      <!--
          <div>
            <div class="d-flex justify-center font-weight-bold overflow-hidden">
              {{ screenImage?.title || "Без названия" }}
            </div>
          </div>
  -->
    </div>
  </a-modal>
</template>

<script>
export default {
  name: "ImageCarousel",

  emits: ["update:carousel"],

  props: {
    items: {
      type: Array,
      default: () => [],
      required: true,
    },

    carouselVisible: {
      type: Boolean,
      default: false,
      required: true,
    },

    index: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      dataCarouselVisible: false,
      imgOnScreen: this.index,
    }
  },

  computed: {
    screenImage() {
      return this.items[this.imgOnScreen]
    },
  },

  methods: {
    normalize(index) {
      if (index < 0) return this.items.length ? this.items.length - 1 : 0
      if (index >= this.items.length) return 0
      return index
    },

    next() {
      this.imgOnScreen = this.normalize(this.imgOnScreen + 1)
    },

    prev() {
      this.imgOnScreen = this.normalize(this.imgOnScreen - 1)
    },

    close() {
      this.$emit("update:carousel", false)
    },
  },

  watch: {
    index() {
      this.imgOnScreen = this.normalize(this.index)
    },
    carouselVisible() {
      this.dataCarouselVisible = this.carouselVisible
    },
  },
}
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 600px) {
  .wrapper {
    min-width: 320px;
    min-height: 320px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image {
    max-width: 480px;
    max-height: 640px;
  }
}

@media only screen and (min-width: 600px) {
  .wrapper {
    min-width: 400px;
    min-height: 400px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image {
    max-width: 570px;
    max-height: 400px;
  }
}

@media only screen and (min-width: 992px) {
  .wrapper {
    min-width: 400px;
    min-height: 400px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image {
    max-width: 900px;
    max-height: 600px;
  }
}

.icon-button {
  border-radius: 100% !important;
}
</style>
