<template>
  <a-modal :visible="dataModalVisible" class="w-25" @cancel="close">
    <template slot="footer">
      <a-button key="back" @click="close()">Закрыть</a-button>
      <a-button key="submit" type="primary" @click="save()">Сохранить</a-button>
    </template>
    <a-card>
      <h3>
        {{
          fileObject.uuid !== null ? "Изменить название" : "Добавить документ"
        }}
      </h3>
      <div class="container">
        <a-form-item label="Наименование">
          <a-input placeholder="Наименование" v-model="fileObject.name" />
        </a-form-item>
        <a-row v-if="fileObject.uuid === null">
          <a-col>
            <div class="large-12 medium-12 small-12 cell">
              <label>
                <input
                  type="file"
                  id="files"
                  ref="file"
                  multiple
                  v-on:change="handleFilesUpload()"
                  accept="image/png, image/jpeg, .pdf"
                />
              </label>
            </div>
            <div class="large-12 medium-12 small-12 cell">
              <div
                v-for="(file, key) in files"
                v-bind:key="key"
                class="file-listing"
              >
                {{ file.name }}
                <span class="remove-file" v-on:click="removeFile(key)">
                  Удалить
                </span>
              </div>
            </div>
            <br />
            <div class="large-12 medium-12 small-12 cell">
              <button v-on:click="addFiles()" class="ant-btn ant-btn-default">
                Выберите фото (PDF, JPEG, PNG)
              </button>
            </div>
            <br />
          </a-col>
        </a-row>
      </div>
    </a-card>
  </a-modal>
</template>

<script>
import axios from "axios"
import { API_PREFIX } from "@/services/api/v1/const"
import { message } from "ant-design-vue"

const MAX_FILE_SIZE = 5 * 1024 * 1024

export default {
  name: "ProductsUploadFileDialog",

  emits: ["update:dialog", "save"],

  props: {
    fileObject: {
      type: Object,
      required: true,
    },
    modalVisible: Boolean,
  },

  data() {
    return {
      file: {},
      files: [],
      dataModalVisible: false,
    }
  },
  computed: {
    canSave() {
      if (this.fileObject.id > 0) return true

      if (this.checkNotEmpty(this.files) !== true) return false

      if (this.checkFileSize(this.files) !== true) return false

      return true
    },
  },

  methods: {
    handleFilesUpload() {
      this.files = this.$refs.file.files
    },
    addFiles() {
      this.$refs.file.click()
    },
    removeFile(key) {
      let arr = Object.values(this.files)
      arr.splice(key, 1)
      this.files = {
        ...arr,
      }
    },

    async save() {
      await this.remote(async () => {
        let files
        if (this.fileObject.uuid === null) files = await this.uploadFile()
        else files = await this.updateFile()
        this.$emit("update:dialog", false)
        this.$emit("save", files)
      })
    },

    async updateFile() {
      return await axios.put(
        `${API_PREFIX}/product/files/${this.fileObject.uuid}`,
        {
          name: this.fileObject.name,
        }
      )
    },

    async uploadFile() {
      const formData = new FormData()
      const file = this.files[0]
      formData.append("product_id", this.fileObject.product_id)
      const linkTitle = this.fileObject.name
      formData.append(
        "name",
        !linkTitle || linkTitle.trim() === "" ? file.name : linkTitle.trim()
      )
      formData.append("file", file, file.name)
      const images = await axios.post(`${API_PREFIX}/product/files`, formData)
      message.success("Документ загружен")
      return images
    },

    async remote(clojure) {
      try {
        await clojure()
      } catch (e) {
        message.error(e)
      }
    },

    close() {
      this.$emit("update:dialog", false)
    },

    checkNotEmpty(files) {
      return (files && files.length === 1) || "Прикрепите файл"
    },

    checkFileSize(files) {
      if (!files || files.length) return true
      if (files[0].size < MAX_FILE_SIZE) return true
      return `Размер файла не должен превышать ${
        Math.floor((10 * MAX_FILE_SIZE) / 1024 / 1024) / 10
      }Мб`
    },
  },

  mounted() {
    this.file = this.fileObject.file || {}
    this.files = []
  },

  watch: {
    modalVisible() {
      this.dataModalVisible = this.modalVisible
    },
    fileObject() {
      this.file = this.fileObject.file || {}
      this.files = []
    },
  },
}
</script>

<style>
input[type="file"] {
  position: absolute;
  top: -500px;
}

div.file-listing {
  width: 200px;
}

span.remove-file {
  color: red;
  cursor: pointer;
  float: right;
}
</style>
