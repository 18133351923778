<template>
  <div>
    <div v-if="isNotEmpty()" class="d-flex flex-wrap">
      <image-preview
        v-for="item in items"
        :key="item.id"
        :item="item"
        @click="$emit('click', $event)"
        @delete="$emit('delete', $event)"
        @edit="$emit('edit', $event)"
      />
    </div>
    <div v-else>Ни одно изображение еще не загружено</div>
  </div>
</template>

<script>
import ImagePreview from "./ImagePreview.vue"

export default {
  name: "ImageList",
  emits: ["click", "delete", "edit"],
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    isNotEmpty() {
      return this.items && this.items.length > 0
    },
  },
  components: { ImagePreview },
}
</script>

<style>
.flex-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
</style>
