<template>
  <div class="rounded px-2 py-2 image-container">
    <div
      class="wrapper clickable"
      @click="$emit('click', item)"
      style="margin-top: 10px"
    >
      <a-icon
        v-if="item.ext === 'pdf'"
        type="file-pdf"
        :style="{ fontSize: '3em' }"
      ></a-icon>
      <img v-else :src="item.link" class="img" />
    </div>
    <div class="name overflow-hidden text-center">{{ item.title }}</div>
    <div class="actions" v-if="haveActions" @click.stop>
      <a-icon v-if="item.isEditable" type="edit" @click="$emit('edit', item)" />
      <a-popconfirm
        :visible="confirmVisible"
        title="Вы действительно хотите удалить это фото?"
        ok-text="Да, конечно"
        cancel-text="Нет, ну что Вы?"
        @confirm="$emit('delete', item)"
        @cancel="hideConfirm()"
      >
        <a-icon
          v-if="item.isDeletable"
          type="delete"
          @click="showConfirm()"
          style="margin-left: 10px"
        />
      </a-popconfirm>
    </div>
  </div>
</template>
<script>
export default {
  name: "ImagePreview",

  emits: ["click", "delete", "edit"],

  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      confirmVisible: false,
    }
  },

  computed: {
    haveActions() {
      return this.item.isEditable || this.item.isDeletable
    },
  },

  methods: {
    showConfirm() {
      this.confirmVisible = true
    },

    hideConfirm() {
      this.confirmVisible = false
    },
  },
}
</script>

<style lang="scss" scoped>
.image-container {
  position: relative;
  border: 2px solid #d0d0d0;
  margin: 0.25rem 0.5rem;
  height: 190px;
  width: 240px;
  display: flex;
  flex-direction: column;
  align-items: inherit;
}

.wrapper {
  width: 200px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
}
.img {
  max-width: 200px;
  max-height: 150px;
}

.image-container .actions {
  transition: opacity 0.5s;
  opacity: 0;
  position: absolute;
  top: 0;
  margin-left: -0.5rem;
  padding: 0.25rem 0.5rem;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.image-container:hover .actions {
  opacity: 1;
}

.clickable {
  cursor: pointer;
}
.text-center {
  text-align: center;
}
</style>
