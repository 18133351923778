<template>
  <div>
    <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
      <a-input type="hidden" :value="productItem.id" />
      <a-form-item label="Каталожный номер">
        <a-input
          :disabled="true"
          placeholder="Каталожный номер"
          v-model="productItem.code"
        />
      </a-form-item>
      <a-form-item label="Наименование товара">
        <a-input
          :disabled="true"
          placeholder="Наименование товара"
          v-model="productItem.name"
        />
      </a-form-item>
      <a-form-item label="Бренд">
        <a-input
          :disabled="true"
          placeholder="Бренд"
          v-model="productItem.brand"
        />
      </a-form-item>
      <a-form-item label="Количество в упаковке">
        <a-input
          v-model="productItem.package_amount"
          :disabled="!isManufacturerAdmin"
          placeholder="Количество в упаковке"
        />
      </a-form-item>
      <a-form-item label="Обьем, литров">
        <a-input
          :disabled="true"
          placeholder="Обьем, литров"
          v-model="productItem.count"
        />
      </a-form-item>
      <a-form-item label="Цена">
        <a-input
          :disabled="true"
          placeholder="Цена"
          v-model="productItem.price"
        />
      </a-form-item>
      <a-form-item label="Характеристики товара">
        <a-input
          v-model="productItem.description"
          :disabled="!isManufacturerAdmin"
          placeholder="Характеристики товара"
        />
      </a-form-item>
      <a-form-item label="Спецификация">
        <a-input
          :disabled="true"
          placeholder="Спецификация"
          v-model="productItem.specification"
        />
      </a-form-item>
      <div>
        <div cols="6" class="d-flex" v-if="isManufacturerAdmin">
          <a-button color="red" type="submit" @click="save()">
            <a-icon type="save" />
            Сохранить
          </a-button>
          <a-button class="ml-6">
            <a-icon type="close" />
            Отменить
          </a-button>
        </div>
      </div>
    </a-form>
  </div>
</template>

<script>
import axios from "axios"
import { mapGetters } from "vuex"
import { API_PREFIX } from "@/services/api/v1/const"
import { message } from "ant-design-vue"

export default {
  name: "ProductsCommon",
  props: {
    product: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      productItem: this.product.data,
    }
  },
  computed: {
    ...mapGetters({
      isManufacturer: "auth/isManufacturer",
      isManufacturerAdmin: "auth/isManufacturerAdmin",
      isDealer: "auth/isDealer",
    }),
  },
  methods: {
    async remote(clojure) {
      try {
        await clojure()
      } catch (e) {
        message.error(e)
      }
    },

    async save() {
      let product = this.productItem
      if (product.id === null)
        product = await axios.post(`${API_PREFIX}/products`, product)
      else
        product = await axios.put(
          `${API_PREFIX}/product/${product.id}`,
          product
        )
      this.productItem = product.data.data
      message.info("Успешно")
    },
  },
  mounted() {},
  watch: {
    product() {
      if (this.product.data) this.productItem = this.product.data
    },
  },
}
</script>

<style scoped>
.ant-input {
  color: #05141f;
}
</style>
