const emptyFile = () => {
  return {
    id: null,
    type_id: null,
    element_id: null,
    link_title: null,
    original_name: null,
    real_name: null,
    mime_type: null,
    path: null,
    is_deleted: 0,
  }
}

export default emptyFile
