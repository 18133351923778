<template>
  <div>
    <p>
      <image-list
        :items="images"
        @click="showFile($event)"
        @delete="deleteFile($event.fileObject)"
        @edit="editFile($event.fileObject)"
      />
    </p>
    <p>
      <a-button v-if="isManufacturerAdmin" type="primary" @click="uploadFile()">
        <a-icon type="plus" />
        Добавить документ
      </a-button>
    </p>
    <products-upload-file-dialog
      :modal-visible="dialog"
      :file-object="editSubject"
      @save="updateImages($event)"
      v-on:update:dialog="dialogUpdated($event)"
    />
    <image-carousel
      :items="carousel_images"
      :index="imageIndex"
      :carousel-visible="carousel"
      v-on:update:carousel="carouselUpdated($event)"
    />
  </div>
</template>

<script>
import { API_PREFIX } from "@/services/api/v1/const"
import axios from "axios"
import { message } from "ant-design-vue"
import ImageList from "@/components/common/images/ImageList.vue"
import ProductsUploadFileDialog from "@/components/products/ProductsUploadFileDialog.vue"
import emptyFileObject from "@/utils/empty/fileObject"
import emptyFile from "@/utils/empty/file"
import emptyProduct from "@/utils/empty/product"
import ImageCarousel from "@/components/common/images/ImageCarousel"
import { mapGetters } from "vuex"

export default {
  name: "ProductsFiles",

  emits: ["update:dialog"],

  props: {
    product: {
      type: Object,
      default: emptyProduct(),
    },
  },

  components: { ImageCarousel, ImageList, ProductsUploadFileDialog },

  data() {
    return {
      files: [],
      editSubject: this.emptyFileObject(),
      dialog: false,
      carousel: false,
      imageIndex: 0,
    }
  },

  computed: {
    ...mapGetters({
      isDealer: "auth/isDealer",
      isManufacturerAdmin: "auth/isManufacturerAdmin",
    }),
    images() {
      let arr = Object.values(this.files)
      return arr.map(map => {
        return {
          fileObject: map,
          id: map.uuid,
          title: map.name,
          ext: map.extension,
          link: map.original_url,
          isEditable: true,
          isDeletable: true,
        }
      })
    },
    carousel_images() {
      let arr = Object.values(this.files)
      return arr
        .filter(x => !["pdf"].includes(x.extension))
        .map(map => {
          return {
            id: map.uuid,
            title: map.name,
            link: map.original_url,
          }
        })
    },
  },

  methods: {
    dialogUpdated(value) {
      this.dialog = value
    },

    carouselUpdated(value) {
      this.carousel = value
    },

    async remote(clojure) {
      try {
        await clojure()
      } catch (e) {
        message.error(e)
      }
    },

    emptyFileObject() {
      const file = {
        ...emptyFile(),
      }
      return {
        ...emptyFileObject(),
        file,
        product_id: this.product.data.id,
      }
    },

    updateImages(images) {
      if (images === undefined) {
        this.files = this.product.data.files
      } else {
        this.files = images.data
      }
    },

    uploadFile() {
      this.editFile(this.emptyFileObject())
    },

    editFile(fileObject) {
      this.editSubject = fileObject
      this.dialog = true
    },

    async deleteFile(fileObject) {
      await this.remote(async () => {
        const images = await axios.delete(
          `${API_PREFIX}/product/files/${fileObject.uuid}`
        )
        message.info("Документ удален")
        this.updateImages(images)
      })
    },

    showFile(data) {
      if (data.ext === "pdf") {
        window.open(data.fileObject.original_url, "_blank")
        return false
      }
      this.imageIndex = this.images.indexOf(data)
      this.carousel = true
    },
  },

  mounted() {
    this.updateImages()
  },

  watch: {
    product() {
      this.updateImages()
    },
  },
}
</script>

<style lang="scss" scoped></style>
