const emptyProduct = () => {
  return {
    id: null,
    company_id: null,
    type_id: null,
    unit_id: null,
    article: null,
    descr: null,
    certificate: null,
    multiply: 1,
    is_profile: 0,
  }
}
export default emptyProduct
