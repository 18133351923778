const emptyFileObject = () => {
  return {
    uuid: null,
    file_id: null,
    product_id: null,
    is_deleted: 0,
  }
}

export default emptyFileObject
